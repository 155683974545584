import { Controller } from "@hotwired/stimulus"
import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

export default class extends Controller {

  connect() {
    var that = this;
    that.lon = that.element.dataset.lon;
    that.lat = that.element.dataset.lat;
    that.mapbox_style = that.element.dataset.mapboxStyle;
    mapboxgl.accessToken = that.element.dataset.accessToken;
    var map = new mapboxgl.Map({
    container: 'map',
    style: that.mapbox_style ? that.mapbox_style : 'mapbox://styles/mapbox/streets-v12',
    center: [that.lon, that.lat],
    zoom: 13,
    attributionControl: false
    });

    // Create a default Marker and add it to the map.
    var marker1 = new mapboxgl.Marker()
    .setLngLat([that.lon, that.lat])
    .addTo(map);

    const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl
    });
  }
}
